import * as React from 'react'
import Layout from '../components/Layout'
import Services from '@/components/Services'
import SEO from '@/components/seo'
import { useLocation } from '@reach/router'
import TopArea from '@/components/Home/Services/TopArea'

const Ser: React.FC<{}> = () => {
  return (
    <Layout>
      <TopArea />
      <Services />
      <SEO title="Services" canonical={`${useLocation().host}`} />
    </Layout>
  )
}

export default Ser
