import React from 'react'

import styled from 'styled-components'
import { Devices } from '@/hooks/useDevice'

const TopArea = () => {
  return (
    <TopW>
      <Container className="container">
        <div className="row">
          <div className="col-lg-12">
            <TContainer className="mx-auto">
              <ImageW>
                <img src="/images/logobanner.svg" />
              </ImageW>
              <ContentW>
                <TopTitle>Services</TopTitle>
                <TopContent>
                  At KAT Electronics, our commitment focuses on tailoring safety solutions
                  meticulously crafted to suit the unique needs of every client. Our mission is to
                  enhance both home and business security.
                </TopContent>
                <TopContent>
                  With comprehensive solutions for electrical, security, and fire systems across
                  Miami-Dade, Broward, and Pompano, we are dedicated to safeguarding today for a
                  safer tomorrow.
                </TopContent>
                <TopContent>
                  We guarantee constant protection of your premises by providing 24/7 monitoring of
                  your safety.
                </TopContent>
              </ContentW>
            </TContainer>
          </div>
        </div>
      </Container>
    </TopW>
  )
}

const TopW = styled.div`
  padding: 30px 0px 50px 0px;

  @media (max-width: 991px) {
    padding-top: 65px;
  }
  @media ${Devices.mobile.mediaQuery} {
    width: 100%;
    padding: 40px 0px 24px 0px;
  }
`
const Container = styled.div`
  @media ${Devices.mobile.mediaQuery} {
    padding-right: 24px !important;
    padding-left: 24px !important;
  }
`
const ContentW = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`
const ImageW = styled.div`
  padding: 25px;
  flex-basis: 38%;
  max-width: 244px;
  & > * {
    object-fit: cover;
    width: 100%;
  }
  @media ${Devices.mobile.mediaQuery} {
    max-width: 212px;
    margin: 0 auto;
  }
`
const TopTitle = styled.h3`
  font-family: BankGothic Md BT, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 41.88px;
  letter-spacing: -0.05em;
  color: #00abec;
  margin-bottom: 10px;
  @media ${Devices.mobile.mediaQuery} {
    font-size: 44px;
    text-align: center;
  }
`
const TopContent = styled.p`
  font-family: Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19.6px;
  color: #6d6e71;
  @media ${Devices.mobile.mediaQuery} {
    line-height: 19.6px;
  }
`
const TContainer = styled.div`
  display: flex;
  gap: 50px;
  max-width: 926px;
  @media ${Devices.mobile.mediaQuery} {
    width: 100%;
    gap: 30px;
    flex-direction: column;
  }
`
export default TopArea
