import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import styled from 'styled-components'
import { Devices } from '@/hooks/useDevice'
import { useViewport } from '@/hooks/useViewport'

interface Service {
  id: string
  name: string
  image: string
  imageUrl: string
  descriptionDetail: string
}

const FeatureArea = () => {
  const { isMobile } = useViewport()

  const data = useStaticQuery(graphql`
    query {
      allServicesJson {
        edges {
          node {
            name
            id
            image
            imageUrl
            descriptionDetail
          }
        }
      }
    }
  `)

  return (
    <ServArea>
      <Container className="container">
        {data.allServicesJson.edges.map(({ node }: { node: Service }) => (
          <Serv key={node.id}>
            <ServCard>
              {isMobile ? (
                <img
                  className="mobile-image"
                  src={`/images/${node.imageUrl}`}
                  alt="Service Image"
                />
              ) : (
                <ServImage src={`/images/${node.image}`} alt="Service Image" />
              )}
              <CardContentW>
                <CardTitle>{node.name}</CardTitle>
                <CardContent>{node.descriptionDetail}</CardContent>
              </CardContentW>
            </ServCard>
          </Serv>
        ))}
      </Container>
    </ServArea>
  )
}

const ServArea = styled.div`
  background: #003d51;
  padding: 40px 0 70px;

  @media ${Devices.mobile.mediaQuery} {
    padding-bottom: 20px;
  }
`

const Container = styled.div`
  @media ${Devices.mobile.mediaQuery} {
    padding-right: 24px !important;
    padding-left: 24px !important;
  }
`

const Serv = styled.div`
  & .mobile-image {
    display: none;

    @media ${Devices.mobile.mediaQuery} {
      display: flex;
      width: 200px;
      margin: 0 auto;
      margin-bottom: 10px;
      padding: 23px 22px;
    }
  }
`

const ServCard = styled.div`
  padding: 40px 60px;
  box-shadow: 10px 10px 20px 0px #00000073;
  margin: 0 auto;
  margin-bottom: 30px;
  display: flex;
  position: relative;
  overflow: hidden;
  height: 430px;
  max-width: 926px;
  justify-content: flex-end;
  background-color: #ffffff;

  @media ${Devices.mobile.mediaQuery} {
    padding: 20px;
    height: auto;
    flex-direction: column;
  }
`

const CardTitle = styled.h3`
  font-family: BankGothic Md BT, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 41.88px;
  letter-spacing: -0.05em;
  color: #00abec;
  margin: 0px;
  padding-right: 160px;

  @media ${Devices.tablet.mediaQuery} {
    padding-right: 80px;
  }
  @media (min-width: 991px) and (max-width: 1023px) {
    padding-right: 210px;
  }
  @media ${Devices.mobile.mediaQuery} {
    padding-right: 80px;
    padding-left: 80px;
  }
  @media (max-width: 462px) {
    padding: 0px;
  }
`

const CardContent = styled.p`
  font-family: Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19.6px;
  color: #6d6e71;
  height: auto;
`

const CardContentW = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-right: 108px;
  flex-basis: calc(100% - 240px);
  justify-content: center;

  @media (min-width: 1440px) {
    flex-basis: calc(100% - 280px);
  }
  @media ${Devices.tablet.mediaQuery} {
    padding-right: 0px;
  }
  @media ${Devices.mobile.mediaQuery} {
    flex-basis: 100%;
    text-align: center;
    padding-right: 0px;
  }
`

const ServImage = styled.img`
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
`

export default FeatureArea
